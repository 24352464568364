import { X } from "lucide-react";
import { useEffect, useState } from "react";

export default function HikariPopup() {
  const [show, setShow] = useState(false);
  const [hideCount, setHideCount] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (show) return setHideCount((prevCount) => prevCount + 1);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 3000 / (hideCount + 1));

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <div
      id="hikari-popup"
      style={{
        display: show ? "block" : "none",
        position: "fixed",
        width: "70vw",
        maxHeight: "90vh",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.5)",
        backgroundColor: "white",
      }}
    >
      <img src="/assets/images/hikari.jpg" />
      <button
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "8px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          border: "none",
          cursor: "pointer",
          color: "black",
        }}
        onClick={() => setShow(false)}
      >
        <X size="24" />
      </button>
    </div>
  );
}
